import React from 'react';

import PropTypes from 'prop-types';

import { size } from 'lodash';

import { REGEX_NUMERO_SEI } from 'utils/constants';
import { formataNumeroSei } from 'utils/tools';

const CLASS_NAME_FORM_CONTROL = 'form-control';
const CLASS_NAME_FORM_CONTROL_ERROR = 'form-control-error';

const NumeroSeiField = ({
	label,
	required,
	placeHolder,
	name,
	value,
	readOnly,
	disabled,
	onChangeHandler,
	isInvalid
}) => {
	const onChangeText = e => {
		let value = e.target.value.replace(/\D/g, ''); // apaga tudo que não é número
		if (value.length > 13) value = value.slice(0, 13);
		value = formataNumeroSei(value);
		onChangeHandler([
			{ name, value },
			{ name: 'errors', value: { [name]: validate(value, required, label) } }
		]);
	};

	return (
		<>
			<label className="control-label">
				{label}
				{required && <span className="required">*</span>}
			</label>
			<input
				className={
					isInvalid === true
						? `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}`
						: `${CLASS_NAME_FORM_CONTROL}`
				}
				type="text"
				placeholder={placeHolder}
				name={name}
				value={value}
				onChange={onChangeText}
				readOnly={readOnly}
				disabled={disabled}
			/>
		</>
	);
};
NumeroSeiField.displayName = 'TextField';

NumeroSeiField.propTypes = {
	title: PropTypes.string,
	label: PropTypes.string,
	required: PropTypes.bool,
	placeHolder: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.string,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	onChangeHandler: PropTypes.func,
	isInvalid: PropTypes.bool
};

export default NumeroSeiField;

export const validate = (value, required, label = 'Campo') => {
	let errors = [];
	if (required) {
		if (!value) {
			errors.push(`${label} deve ser informado`);
		} else if (size(value) === 0 || !REGEX_NUMERO_SEI.test(value)) {
			errors.push('Insira um número SEI válido');
		}
	}
	return errors;
};
